'use client';

import { TailwindBreakPoints } from '@src/typescriptGlobals/types';
import { RootStateTypes, useTypedAppSelector } from '@src/redux/store/store.types';

import NextImage from '@src/components/Shared/NextImage';
import Container from '@src/components/Shared/Container';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import CarouselContainer from '@src/components/Shared/CarouselContainer';
import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import RenderButtons from '@src/components/Shared/RenderButtons';
import CarouselIndicators from '@src/components/Shared/CarouselIndicators';

import useCarouselAnimation from '@src/hooks/useCarouselAnimation';
import useCarouselIndicatorAnimation from '@src/hooks/useCarouselIndicatorAnimation';

import { ClientSuccessPropTypes } from './ClientSuccess.types';

const ClientSuccess: React.FC<ClientSuccessPropTypes> = ({ data, id }) => {
  const { carouselRef, containerRef, handleAddCarouselItemsToRef, activeIndex, handleJumpToSlide, maxIndex } =
    useCarouselAnimation({});
  const { width } = useTypedAppSelector((state: RootStateTypes) => state.windowRXS);

  const { handleAddIndicatorRef } = useCarouselIndicatorAnimation({});

  const onNavigate = (slug: string) => {
    return () => {
      window.open(slug, '_blank');
    };
  };

  return (
    <Container id={id} containerRef={containerRef}>
      {data?.bodyCopy?.json?.content && (
        <div className='mx-auto mb-[4.5rem] max-w-[103.7rem] text-center text-[1.8rem]'>
          <RenderBodyCopy bodyCopy={data?.bodyCopy?.json?.content} />
        </div>
      )}

      <CarouselContainer
        carouselRef={width >= TailwindBreakPoints.lg ? null : carouselRef}
        className='lg:justify-center [&>*:not(:last-child)]:mr-[1.6rem] md:[&>*:not(:last-child)]:mr-[2.4rem] 2xl:[&>*:not(:last-child)]:mr-[3.6rem]'
      >
        {data?.topicCollection?.items?.map((service) => {
          const linkElement = service?.link ? (
            <div
              key={service?.sys?.id}
              ref={width >= TailwindBreakPoints.lg ? null : handleAddCarouselItemsToRef}
              className='flex w-[31.4rem] flex-none flex-shrink-0 cursor-pointer flex-col overflow-hidden pb-[2rem]'
              onClick={onNavigate(service.link)}
            >
              <NextImage
                src={service?.mediaPrimary?.url}
                alt={service?.mediaPrimary?.description}
                className='mb-[1.2rem] h-[25.6rem] w-full overflow-hidden rounded-[0.8rem]'
              />

              <div className='mx-auto mt-[2rem] flex flex-grow flex-col'>
                <p className='mb-[1.8rem] text-[2.2rem] font-normal leading-[1] text-grey-dark'>
                  {service.title}
                </p>
                <ParagraphSeparator className='flex flex-grow flex-col [&>div:has(a):last-child]:mt-auto'>
                  <RenderBodyCopy
                    bodyCopy={service?.bodyCopy?.json?.content}
                  />
                </ParagraphSeparator>
                <RenderButtons
                  className="[&>div]:w-[100%]"
                  items={service?.buttonCollection?.items}
                />
              </div>
            </div>
          ) : (
            // If link is empty, just render the content without the Link wrapper
            <div
              key={service?.sys?.id}
              ref={width >= TailwindBreakPoints.lg ? null : handleAddCarouselItemsToRef}
              className='flex w-[31.4rem] flex-none flex-shrink-0 flex-col overflow-hidden pb-[2rem] '
            >
              <NextImage
                src={service?.mediaPrimary?.url}
                alt={service?.mediaPrimary?.description ?? 'VALD Service Image'}
                className='mb-[1.2rem] h-[25.6rem] w-full overflow-hidden rounded-[0.8rem]'
              />

              <div className='mx-auto mt-[2rem] flex flex-grow flex-col'>
                <p className='mb-[1.8rem] text-[2.2rem] font-normal leading-[1] text-grey-dark'>
                  {service.title}
                </p>
                <ParagraphSeparator className='flex flex-grow flex-col [&>div:has(a):last-child]:mt-auto'>
                  <RenderBodyCopy
                    className='text-[1.6rem] font-[200]'
                    bodyCopy={service?.bodyCopy?.json?.content}
                  />
                </ParagraphSeparator>
                <RenderButtons
                  className="[&>div]:w-[100%]"
                  items={service?.buttonCollection?.items}
                />
              </div>
            </div>
          );

          return linkElement;
        })}
      </CarouselContainer>

      <CarouselIndicators
        className='flex justify-center'
        maxIndex={maxIndex}
        handleJumpToSlide={handleJumpToSlide}
        handleAddIndicatorRef={handleAddIndicatorRef}
        activeIndex={activeIndex}
      />
    </Container>
  );
};

export default ClientSuccess;
