'use client';

import React from 'react';

import { useTypedAppSelector } from '@src/redux/store/store.types';

import useLayoutEffectExceptOnMount from '@src/hooks/useLayoutEffectExceptOnMount';
import useAllImagesLoaded from '@src/hooks/useAllImagesLoaded';

import scrollTo from '@src/utils/scrollTo';

import { ScrollToContainerPropTypes } from './ScrollToContainer.types';

const ScrollToContainer: React.FC<ScrollToContainerPropTypes> = ({ children }) => {
  const { expectedAssets, loadedAssets } = useTypedAppSelector((state) => state.svgLoaderRXS);
  const allImagesLoaded = useAllImagesLoaded()

  const heightOfTopNavigation = 74;
  const heightOfBanner = 34;
  const offsetY = heightOfTopNavigation + heightOfBanner;

  useLayoutEffectExceptOnMount(() => {
    const hash = window.location.hash;

    const allLoaded = () =>
      expectedAssets.size > 0 && [...expectedAssets].every((id) => loadedAssets.has(id));

    if (hash && allLoaded()) {
      scrollTo({ target: hash, offsetY, ease: 'power4.out', duration: 2 })()
    };
  }, [loadedAssets, expectedAssets]);

  useLayoutEffectExceptOnMount(() => {
    const hash = window.location.hash;

    if (expectedAssets.size < 1 && hash && allImagesLoaded) {
      scrollTo({ target: hash, offsetY, ease: 'power4.out', duration: 2 })();
    }

  }, [allImagesLoaded]);

  return <>{children}</>;
};

export default ScrollToContainer;
