import React from 'react';

import { CarouselIndicatorsPropTypes } from './CarouselIndicators.types';

const CarouselIndicators: React.FC<CarouselIndicatorsPropTypes> = ({
  className,
  maxIndex,
  handleJumpToSlide,
  handleAddIndicatorRef,
  activeIndex,
}) => {
  if (maxIndex > 0) {
    return <div className={`items-center ${className}`}>
      {maxIndex &&
        [...Array(maxIndex + 1)]?.map((_, index) => {
          return (
            <div
              key={`indicator-${index}`}
              ref={handleAddIndicatorRef}
              onClick={handleJumpToSlide({ index })}
              className={`mr-[0.8rem] h-[0.8rem] w-[0.8rem] cursor-pointer rounded-full border-[0.1rem] border-primary ${index === activeIndex ? 'h-[1rem] w-[1rem] bg-primary' : 'bg-transparent'}`}
            ></div>
          );
        })}
    </div>
  } else {
    return null
  }
};

export default CarouselIndicators;
