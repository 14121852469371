import React from 'react';

import { ParagraphSeparatorPropTypes } from './ParagraphSeparator.types';

const ParagraphSeparator: React.FC<ParagraphSeparatorPropTypes> = ({
  className = '',
  style = {},
  children,
}) => {
  return <div style={style} className={`[&>*:not(:last-child)]:mb-[1.6rem] ${className}`}>{children}</div>;
};

export default ParagraphSeparator;
