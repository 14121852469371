import React from 'react';

import { CarouselContainerPropTypes } from './CarouselContainer.types';

const CarouselContainer: React.FC<CarouselContainerPropTypes> = ({
  carouselRef,
  className,
  children,
}) => {
  return (
    <div ref={carouselRef} className={`flex mb-[1.7rem] 2xl:mb-[3.8rem] ${className}`}>
      {children}
    </div>
  );
};

export default CarouselContainer;
